import { isSmall10char, isInteger } from '@/utils'
export const option = {
  menuBtn: false,
  column: [
    {
      label: '字段名称',
      prop: 'fieldName',
      span: 20,
      rules: [
        {
          required: true,
          message: '字段名称必填'
        },
        {
          validator: isSmall10char
        }
      ]
    },
    {
      label: '排序',
      prop: 'sortNumber',
      span: 20,
      rules: [
        {
          required: true,
          message: '排序必填'
        },
        {
          validator: isInteger
        }
      ]
    }
  ]
}